import React, {useState, useEffect} from 'react';

function Main() {

    const [post, setPost] = useState(undefined);
    const [sensorNum, setSensorNum] = useState(0);

    useEffect(() => {
        fetch('https://proxy.cep-hd-dlp.chuden.co.jp/data-n8suh89sqhcjo5g5/data-api/latest?id=CgETViZ2&subscription-key=0e35a874f02a4887adc1ed0b2561f645', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            setPost(data)
        })
    },[]);

    let borderCo2ColorClass;
    let borderTempColorClass;
    let borderHumColorClass;
    const co2Value = post?.[sensorNum]?.co2;
    const tempValue = post?.[sensorNum]?.temperature;
    const humValue = post?.[sensorNum]?.relativeHumidity;

    if (co2Value === undefined) {
    borderCo2ColorClass = 'border-blue-200';
    } else if (co2Value <= 400) {
    borderCo2ColorClass = 'border-blue-100';
    } else if (co2Value <= 500) {
    borderCo2ColorClass = 'border-blue-200';
    } else if (co2Value <= 600) {
    borderCo2ColorClass = 'border-blue-300';
    } else if (co2Value <= 700) {
    borderCo2ColorClass = 'border-blue-400';
    } else if (co2Value <= 800) {
    borderCo2ColorClass = 'border-blue-500';
    }

    if (tempValue === undefined) {
    borderTempColorClass = 'border-red-200';
    } else if (tempValue <= 20) {
    borderTempColorClass = 'border-red-100';
    } else if (tempValue <= 25) {
    borderTempColorClass = 'border-red-200';
    } else if (tempValue <= 30) {
    borderTempColorClass = 'border-red-300';
    } else if (tempValue <= 35) {
    borderTempColorClass = 'border-red-400';
    } else if (tempValue <= 40) {
    borderTempColorClass = 'border-red-500';
    }

    if (humValue === undefined) {
    borderHumColorClass = 'border-green-200';
    } else if (humValue <= 40) {
    borderHumColorClass = 'border-green-100';
    } else if (humValue <= 50) {
    borderHumColorClass = 'border-green-200';
    } else if (humValue <= 60) {
    borderHumColorClass = 'border-green-300';
    } else if (humValue <= 70) {
    borderHumColorClass = 'border-green-400';
    } else if (humValue <= 80) {
    borderHumColorClass = 'border-green-500';
    }

    if (!post) {
        return null;
    }

    return (
        <div className="pt-12 pb-16">
            {/* 表示するセンサーを指定するボタン部 */}
            <div className="pb-4 flex justify-center">
                {sensorNum == 0 ? 
                    <button
                        disabled
                        type="button"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-600"
                        onClick={() => setSensorNum(prevState => prevState - 1)}
                    >
                    ＜＜
                    </button> :
                    sensorNum == 7 ?
                    <button
                        type="button"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setSensorNum(prevState => prevState - 2)}
                    >
                    ＜＜
                    </button> :
                    <button
                        type="button"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setSensorNum(prevState => prevState - 1)}
                    >
                    ＜＜
                    </button>
                }
                {post === undefined ? "" : <div className="text-4xl font-bold mx-8 ">{post[sensorNum].sensorName}</div>}
                {sensorNum == 7 ?
                    <button
                    disabled
                    type="button"
                    className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-600"
                    onClick={() => setSensorNum(prevState => prevState - 1)}
                    >
                    ＞＞
                    </button> :
                    sensorNum == 5 ?
                    <button
                        type="button"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setSensorNum(prevState => prevState + 2)}
                        >
                    ＞＞
                    </button> :
                    <button
                        type="button"
                        className="rounded-md px-2.5 py-1.5 text-sm font-semibold bg-cyan-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setSensorNum(prevState => prevState + 1)}
                        >
                    ＞＞
                    </button>
                }           
            </div>
            {/* CO2濃度を表示するフレックスボックス */}
                    <div className='grid'>
            <div className=" items-center justify-center flex">
                <div className='md:flex-col '>
                    <div className={`text-center border-4 ${borderCo2ColorClass} px-8 py-8 rounded-md m-4 float-left grid-cols-4`}>
                        <h1 className="text-3xl font-medium p-4">
                            現在の二酸化炭素濃度は
                        </h1>
                        {post === undefined ? "" : <div className="text-7xl font-semibold">{post[sensorNum].co2}</div>}
                        <div className="text-2xl italic text-right">ppm</div>
                    </div>
                    {/* 気温と湿度を縦並びで表示するフレックスボックス */}
                    <div className={`text-center border-4 ${borderTempColorClass} px-16 py-8 rounded-md m-4 float-left grid-cols-4`}>
                        <h1 className="text-3xl font-medium p-4">
                            現在の室温は
                        </h1>
                        {post === undefined ? "" : <div className="text-7xl font-semibold">{post[sensorNum].temperature}</div>}
                        <div className="text-2xl italic text-right">℃</div>
                    </div>
                    <div className={`text-center border-4 ${borderHumColorClass} px-16 py-8 rounded-md m-4 float-left grid-cols-4`}>
                        <h1 className="text-3xl font-medium p-4">
                            現在の湿度は
                        </h1>
                        {post === undefined ? "" : <div className="text-7xl font-semibold">{post[sensorNum].relativeHumidity}</div>}
                        <div className="text-2xl italic text-right">%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default Main;
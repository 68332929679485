import React, { useEffect, useRef, useState } from 'react';
import { Chart, ScatterController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(ScatterController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const sensorInfo = [
  { name: 'Ｒ３ー４０１', color: '#FF0000' },
  { name: 'Ｒ３ー４０３', color: '#FFA500' },
  { name: 'Ｒ３ー３０１', color: '#FFFF00' },
  { name: 'Ｒ３ー４Ｆ_ＥＨ', color: '#008000' },
  { name: 'Ｒ３ー３Ｆ_ＥＨ', color: '#00FFFF' },
  { name: 'Ｒ３ー１Ｆ_ＥＨ', color: '#0000FF' },
  { name: 'Ｒ３ーB１Ｆ_ＥＨ', color: '#800080' }
];

type SensorData = {
  x: number;
  y: number;
};

type SensorInfo = {
  name: string;
  color: string;
  co2Data?: SensorData[];
  tempData?: SensorData[];
  RHData?: SensorData[];
};

const fetchCsvData = async (): Promise<string> => {
  const date = new Date();
  const currTime = Math.floor(date.getTime() / 1000);
  const startTime = currTime - 3600 * 24;
  const response = await fetch(`https://proxy.cep-hd-dlp.chuden.co.jp/data-n8suh89sqhcjo5g5/data-api/day-csv?id=CgETViZ2&subscription-key=0e35a874f02a4887adc1ed0b2561f645&startDate=${startTime}`);
  return response.text();
};

const convertArray = (data: string, sensorName: string): number[][] => {
  const dataArray: number[][] = [];
  const dataString = data.split('\r\n');
  dataString.forEach((line) => {
    const row = line.split(',');
    if (row[1] === sensorName) {
      dataArray.push(row.slice(3, 7).map(parseFloat));
    }
  });
  return dataArray;
};

const CsvGraph: React.FC = () => {
  const canvas1Ref = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  const [sensorData, setSensorData] = useState<SensorInfo[]>(sensorInfo);

  useEffect(() => {
    const getCsvGraph = async () => {
      const rawData = await fetchCsvData();
      const date = new Date();
      const currTime = Math.floor(date.getTime() / 1000);

      const updatedSensorData = sensorInfo.map(sensor => {
        const data = convertArray(rawData, sensor.name);

        data.forEach(row => {
          row[3] -= currTime;
          row[3] /= 3600; // convert seconds to hours
        });

        const co2Data: SensorData[] = data.map(row => ({ x: row[3], y: row[0] }));
        const tempData: SensorData[] = data.map(row => ({ x: row[3], y: row[1] }));
        const RHData: SensorData[] = data.map(row => ({ x: row[3], y: row[2] }));

        return { ...sensor, co2Data, tempData, RHData };
      });

      setSensorData(updatedSensorData);

      drawGraph(canvas1Ref.current!, updatedSensorData, 'temperature [°C]', 'tempData');
    };

    getCsvGraph();
  }, []);

  const drawGraph = (canvas: HTMLCanvasElement, data: SensorInfo[], ylabel: string, dataKey: 'co2Data' | 'tempData' | 'RHData') => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(canvas, {
      type: 'scatter',
      data: {
        datasets: data.map(sensor => ({
          label: sensor.name,
          showLine: true,
          data: sensor[dataKey] || [], // Provide an empty array if data is undefined
          borderColor: sensor.color,
        })),
      },
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: 'Time [hour]',
              font: {
                size: 14,
              },
            },
          },
          y: {
            title: {
              display: true,
              text: ylabel,
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  };

  return (
    <div>
      <canvas id="stage1" ref={canvas1Ref}></canvas>
    </div>
  );
};

export default CsvGraph;

function Footer() {
    return (
        <div className='bg-gray-200 pt-16 px-4'>
            <h1 className="text-s text-right text-white">
                presented by Team Toraneko
            </h1>
        </div>
    );
}

export default Footer;
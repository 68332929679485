import React, { useEffect, useRef, useState } from 'react';
import { Chart, ScatterController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(ScatterController, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const sensorInfo = [
  { name: 'Ｒ３ー４０１', color: '#FF0000' },
  { name: 'Ｒ３ー４０３', color: '#FFA500' },
  { name: 'Ｒ３ー３０１', color: '#FFFF00' },
  { name: 'Ｒ３ー４Ｆ_ＥＨ', color: '#008000' },
  { name: 'Ｒ３ー３Ｆ_ＥＨ', color: '#00FFFF' },
  { name: 'Ｒ３ー１Ｆ_ＥＨ', color: '#0000FF' },
  { name: 'Ｒ３ーB１Ｆ_ＥＨ', color: '#800080' }
];

type SensorData = {
  x: number;
  y: number;
};

type SensorInfo = {
  name: string;
  color: string;
  PredCO2?: SensorData[];
};


const CsvGraph: React.FC = () => {
  const canvas4Ref = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  const [json, setJson] = useState<any[] | null>(null);
  const [sensorData, setSensorData] = useState<SensorInfo[]>(sensorInfo);

  useEffect(() => {
    fetch('http://localhost:1234/', {method: 'GET'})
        .then(res => res.json())
        .then((json: any[]) => {
            setJson(json)
        })
        .catch(error => console.error('Error:', error));
    }, []);

  useEffect(() => {
    const getCsvGraph = () => {
        const predData: SensorData[][] = [[],[],[],[],[],[],[]]
        var predArray: SensorData = {x: 0, y: 0}
        if (json !== null){
            const jsonDataArray = Object.values(json);
            for (var i=0; i<7; i++){
              for (var j=1; j<=48; j++){
                var Num = ( '00' + j ).slice( -2 );
                var dataNum = "data"+Num;
                const xValue = jsonDataArray[i][i][dataNum]?.UNIXTime;
                const yValue = jsonDataArray[i][i][dataNum]?.PredCO2;

                if (xValue !== undefined && yValue !== undefined) {
                  const predArray: SensorData = { x: xValue, y: yValue };
                  predData[i].push(predArray);
                } 
              }
            }
        }

        const updatedSensorData = sensorInfo.map((sensor, index) => {
          return { ...sensor, PredCO2: predData[index] };
        });
    
          setSensorData(updatedSensorData);

        drawGraph(canvas4Ref.current!, updatedSensorData, 'Predict CO2 density [ppm]', 'PredCO2');
      };
      getCsvGraph();
    }, [json]);

  const drawGraph = (canvas: HTMLCanvasElement, data: SensorInfo[], ylabel: string, dataKey: 'PredCO2') => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(canvas, {
      type: 'scatter',
      data: {
        datasets: data.map(sensor => ({
          label: sensor.name,
          showLine: true,
          data: sensor[dataKey] || [], // Provide an empty array if data is undefined
          borderColor: sensor.color,
          borderDash: [10, 5],
        })),
      },
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: 'Time [hour]',
              font: {
                size: 14,
              },
            },
          },
          y: {
            title: {
              display: true,
              text: ylabel,
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  };

  return (
    <div>
      <canvas id="stage4" ref={canvas4Ref}>
      </canvas>
    </div>
  );
};

const MachineChart: React.FC = () => {

  const [json, setJson] = useState<any[] | null>(null); 
  useEffect(() => {
    fetch('http://localhost:1234/', {method: 'GET'})
        .then(res => res.json())
        .then((json: any[]) => {
            setJson(json)
        })
        .catch(error => console.error('Error:', error));
    }, []);

  return (
    <div className="mb-16">
      <div className="text-lg font-sans font-semibold text-center underline">今後のCO2濃度予測値</div>
      <div className="w-8/12 mx-auto">
        <CsvGraph />
      </div>
      {json === null ? "" : (
      <div className="flex justify-center gap-5 text-xs">
        {(() => {
          const showAccuracy = []
          for (let i = 0; i < 7; i++) {
            showAccuracy.push(<div key={i}>{json[i][i].SensorName} : {(json[i][i].Accuracy*100).toPrecision(4)}%</div>);
          }
          return showAccuracy;
        })()}
      </div>
      )}      
    </div>
  );
};

export default MachineChart;

import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import Chart3 from './Chart3';
import MachineChart from './MachineChart';

function App() {
  return (
    <div className="flex flex-col">
      <Header />
      <Main />
      <MachineChart />
      <div className="text-lg font-sans font-semibold text-center underline">過去のデータ</div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3">
          <Chart1 />
        </div>
        <div className="w-full md:w-1/3">
          <Chart2 />
        </div>
        <div className="w-full md:w-1/3">
          <Chart3 />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;